<template>
  <div>
    <router-link
      v-if="checkIfUserHasPermission(permissions)"
      :to="to"
      :target="targetBlank ? '_blank' : ''"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
            <v-icon dark>{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ label }}</span>
      </v-tooltip>
    </router-link>
    <template v-else>{{ $t("NO_ACTION_RIGHTS") }}</template>
  </div>
</template>

<script>
import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],

  props: {
    label: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    },

    permissions: {
      type: Array,
      default: () => []
    },

    to: {
      type: Object,
      required: true
    },

    targetBlank: {
      type: Boolean,
      required: false
    }
  }
};
</script>
