<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("USERS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['users-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i> {{ $t("ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <UserAlertBox></UserAlertBox>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col>
              <FormSelect
                id="store-role-input"
                type="select"
                name="roles"
                :options="roles"
                clearable="true"
                v-model="filters.roleId"
                :i18n="{ label: 'ROLE' }"
              ></FormSelect>
            </b-col>
            <b-col>
              <FormSelect
                id="store-city-input"
                type="select"
                name="cities"
                :options="cities"
                clearable="true"
                v-model="filters.cityId"
                :i18n="{ label: 'CITY' }"
              ></FormSelect>
            </b-col>
            <b-col>
              <FormSelect
                id="store-city-input"
                type="select"
                name="stores"
                :options="stores"
                clearable="true"
                v-model="filters.storeId"
                :i18n="{ label: 'STORE' }"
              ></FormSelect>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingUser"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.email="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              {{ item.email }}</router-link
            >
            <template v-else>{{ item.email }}</template>
          </template>

          <template v-slot:item.firstName="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              <v-avatar
                v-if="true"
                :color="item.userName | getInitialsColor"
                size="36"
              >
                <span class="white--text headline">{{
                  item.userName | getInitials
                }}</span>
              </v-avatar>
              {{ item.firstName }}
            </router-link>
            <template v-else
              ><v-avatar
                v-if="true"
                :color="item.userName | getInitialsColor"
                size="36"
              >
                <span class="white--text headline">{{
                  item.userName | getInitials
                }}</span>
              </v-avatar>
              {{ item.firstName }}</template
            >
          </template>

          <template v-slot:item.lastName="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              {{ item.lastName }}</router-link
            >
            <template v-else>{{ item.lastName }}</template>
          </template>

          <template v-slot:item.store="{ item }">
            {{ storeName(item) }}
          </template>

          <template v-slot:item.active="{ item }">
            <v-chip :color="getColor(item.active)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.active) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.active) }}</v-chip
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <ActionButton
              :to="{ name: 'edit-user', params: { id: item.id } }"
              :permissions="['users-update']"
              :label="$t('EDIT')"
              :icon="'mdi-pencil'"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";
import UserAlertBox from "@/view/content/alertbox/UserContractAlert.vue";

//User Module
import { FETCH_USERS } from "@/modules/user/store/user.module";

//Role Module
import { FETCH_ROLES } from "@/modules/role/store/role.module";

//Shared Modules
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import _ from "lodash";
export default {
  mixins: [permissionMixin],
  name: "UserList",
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      roles: [],
      rolesParams: {
        page: 1,
        itemsPerPage: 50,
        query: ""
      },
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      cities: [],
      stores: [],
      filters: {
        roleId: null,
        cityId: null,
        storeId: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Store", value: "store" },
        { text: "Active", value: "active" },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    FormSelect,
    SearchTools,
    ActionButton,
    UserAlertBox
  },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: { name: "list-users" } },
      { title: "Users List" }
    ]);
    vm.getRoles();
    vm.getSelects();
  },
  computed: {
    ...mapGetters(["getUsers", "isLoadingUser"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        roleId: this.filters.roleId,
        cityId: this.filters.cityId,
        storeId: this.filters.storeId
      };
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_USERS, apiParams)
        .then((data) => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function () {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-users" });
          }
        });
    },
    getRoles(search, loading = function () {}) {
      let vm = this;
      let params = this.rolesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ROLES, apiParams)
        .then((data) => {
          vm.roles = data.data.items;
          vm.totalRoles = data.data.totalItemsCount;
          vm.$nextTick(function () {
            vm.firstLoader = false;
          });
        })
        .catch((response) => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    storeName(user) {
      if (_.size(user.stores) == 1) {
        let userStore = user.stores[0];
        if (userStore) {
          return userStore.nameWithCity;
        } else {
          return "Single";
        }
      } else {
        return "-Multiple-";
      }
    },
    getSelects() {
      let vm = this;
      let payload = {
        cities: {
          fields: ["id", "name"],
          search: ""
        },
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then((data) => {
          vm.cities = data.data.cities;
          vm.stores = data.data.stores;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    }
  }
};
</script>
