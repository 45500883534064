<template>
  <div>
    <v-autocomplete
      :label="$t(i18n.label)"
      :items="options"
      :item-value="(options) => (reduceKey ? options[reduceKey] : options.id)"
      item-text="name"
      :hide-details="isInvalid ? false : hideDetails ? hideDetails : true"
      @input="setSelected"
      :clearable="clearable ? clearable : false"
      v-bind="$attrs"
      v-model="value"
      :error="isInvalid"
      :error-count="'1'"
      :error-messages="errorMessage"
    ></v-autocomplete>
    <b-form-invalid-feedback
      v-if="i18n.validation"
      :id="'invalid-' + feedback_id"
    >
      {{ $t(i18n.validation, validations.params) }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback v-else :id="'invalid-' + feedback_id">
      {{ $t("INVALID", { name: "" }) }}
    </b-form-invalid-feedback>
    <b-form-valid-feedback :id="'valid-' + feedback_id">
      {{ $t("VALID") }}
    </b-form-valid-feedback>
  </div>
</template>
<script>
import i18n from "@/core/plugins/vue-i18n";
export default {
  name: "FormSelect",
  inheritAttrs: false,
  props: {
    /**
     * Validation id of the error
     */
    feedback_id: String,
    /**
     * All options existing in the select options
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Clearable True/False
     */
    clearable: Boolean,
    /**
     * All Translation i18n keys used
     */
    i18n: {
      type: Object,
      default: function () {
        return {};
      }
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: function () {
        return {};
      }
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: true
    },
    /**
     * V-Model
     */
    value: {},
    /**
     * what the Select returns e.g. ID
     */
    reduceKey: String
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    isInvalid: function () {
      if (this.validateState == false) {
        return true;
      }
      return false;
    },
    errorMessage: function () {
      if (this.validateState == false) {
        return i18n.t(this.i18n.validation, this.validations.params);
      }
      return "";
    }
  },
  methods: {
    setSelected(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>
